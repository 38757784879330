import React from 'react'
import { Link, graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/Layout'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout location={this.props.location} title={siteTitle} extraClassname="container-reading">
        <SEO
          title={siteTitle}
          keywords={[`blog`, `dwb`, `董文博`, `dwbbb`, `dwbbb.com`,`javascript`, `reading`]}
        />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          const process = node.frontmatter.process;
          const tags = node.frontmatter.tags;
          const percent = Math.floor(process[0] / process[1] * 100);
          let level = '';
          if (percent <= 20) {
            level = 'low';
          } else if (percent > 20 && percent < 100) {
            level = 'middle';
          } else if (percent === 100) {
            level = 'complete';
          }
          return (
            <article className="m-post" key={node.fields.slug} onClick={() => window.open(node.fields.slug, '_self')}>
                {
                  node.frontmatter.photos && node.frontmatter.photos.length && node.frontmatter.photos[0] ?
                  <div className="m-post-img" style={{'backgroundImage': `url(${node.frontmatter.photos && node.frontmatter.photos.length && node.frontmatter.photos[0]})`}} /> : null
                }
                <div className="m-post-content">
                  <h3 className="title">{title}</h3>
                  <div className="content">
                    {
                      tags.map(item => <div className="item tag">{item}</div>)
                    }
                    <div className="item"></div>
                    <div className={`item process ${level}`}>
                      <div className="percent">{percent}%</div>
                      <div className="line-base">
                        <div className="line-highlight" style={{ width: percent + '%' }}></div>
                      </div>
                    </div>
                  </div>
                </div>
            </article>
          )
        })}
        <div></div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query readingPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: {
          type: {
            eq: "reading"
          }
        }
      }
    ) {
      edges {
        node {
          excerpt(
            format: PLAIN
            pruneLength: 90
            truncate: true
          )
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            type
            photos
            tags
            process
          }
        }
      }
    }
  }
`
